import React from "react";
import Layout from "~components/base/layout";
import SEO from "~components/base/seo";
import Products from "~components/products/products";
import TypeAnimation from 'react-type-animation';

const ContentPage = () => (
    <Layout>
        <SEO title="Content and PR Strategy "/>
        <section className="page-heading">
            <div className="container">
                <TypeAnimation
                    cursor={false}
                    sequence={['Content and', 1500, 'Content and PR', 1500, 'Content and PR Strategy', 1500]}
                    className={'title'}
                    wrapper="h1"
                />
            </div>
        </section>
        <section className="">
            <div className="container">
                <Products>
                    <div title="Monthly Approach" price="$2,500 per month" id="content-monthly">
                        We provide a visual language plan, strategic content outline, graphic templates and a
                        bi-weekly check in to review engagement and promoted content. This includes a 1 hour
                        brainstorming session, 8 hours of planning and creation, 1 hour review of our plan and
                        creations and 2x1 hour check-ins per month.
                    </div>
                    <div title="Quarterly Approach" price="$6,000 per quarter" id="content-quarterly">
                        We provide a visual language plan, strategic content outline, graphic templates and a bi-weekly
                        check in to review engagement and promoted content. This includes a 1 hour brainstorming
                        session, 8 hours of planning and creation, 1 hour review of our plan and creations and 2x1 hour
                        check-ins per month.
                    </div>
                </Products>
            </div>
        </section>
    </Layout>
)

export default ContentPage;
